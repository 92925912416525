import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// Импортируйте иконки
import youtubeIcon from './youtube.png';
import telegramIcon from './telegram.png';

const Footer = () => {
    return (
        <footer className="text-center bg-dark" style={{ background: 'var(--bs-black)', borderStyle: 'none', borderColor: 'rgb(0,0,0)', '--bs-dark': '#000000', '--bs-dark-rgb': '0,0,0' }}>
            <div className="container text-white" style={{ background: 'var(--bs-black)', color: 'white', padding: '10px 0' }}>
                <ul className="list-inline">
                    <li className="list-inline-item me-4" style={{ marginTop: '10px' }}>
                        <a href="https://t.me/SQL_Samurai" target="_blank" rel="noopener noreferrer">
                            <img src={telegramIcon} alt="Telegram" width="32" height="32" className="text-light" />
                        </a>
                    </li>
                    <li className="list-inline-item me-4" style={{ marginTop: '10px' }}>
                        <a href="https://www.youtube.com/@SQL_Samurai" target="_blank" rel="noopener noreferrer">
                            <img src={youtubeIcon} alt="YouTube" width="32" height="32" className="text-light" />
                        </a>
                    </li>
                </ul>
                <p className="mb-0" style={{ color: 'white' }}>Copyright © 2024 SQL-Samurai</p>
            </div>
        </footer>
    );
}

export default Footer;
