import React from 'react';

const QueryResultTable = ({ result, columns, error }) => {
    return (
        <div style={{ maxHeight: '80vh', overflowY: 'auto' }}>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {result.length > 0 ? (
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead>
                        <tr>
                            {columns.map((column) => (
                                <th key={column} style={{ border: '1px solid #ddd', padding: '10px', backgroundColor: '#f2f2f2' }}>
                                    {column}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {result.map((row, index) => (
                            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white' }}>
                                {columns.map((column) => (
                                    <td key={column} style={{ border: '1px solid #ddd', padding: '10px' }}>
                                        {row[column]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Нет результатов для отображения</p>
            )}
        </div>
    );
};

export default QueryResultTable;
