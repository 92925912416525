// // WorkbookList.js
// import React from 'react';
// import { SyntaxHighlighter, prismDarcula } from 'react-syntax-highlighter';
// import { VStack } from "@chakra-ui/react";

// const WorkbookList = ({ workbooks }) => {
//     return (
//         <VStack w="100%" spacing={4} align="stretch" overflow="auto">
//             {workbooks.map((workbook, index) => (
//                 <div className="card" style={{ width: '100%' }} key={index}>
//                     <div className="card-body">
//                         <h5 className="card-title">{workbook.workbook_name}</h5>
//                         <h6 className="card-subtitle mb-2 text-muted">
//                             {workbook.explanations}
//                         </h6>
//                         <p className="card-text">
//                             Решение:
//                             <SyntaxHighlighter language="sql" style={prismDarcula}>
//                                 {workbook.solution}
//                             </SyntaxHighlighter>
//                         </p>
//                     </div>
//                 </div>
//             ))}
//         </VStack>
//     );
// };

// export default WorkbookList;
//-------------------------
// WorkbookList.js
import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { VStack } from "@chakra-ui/react";

const WorkbookList = ({ workbooks }) => {
    return (
        <VStack w="100%" spacing={4} align="stretch" overflow="auto">
            {workbooks.map((workbook, index) => (
                <div className="card" style={{ width: '100%' }} key={index}>
                    <div className="card-body">
                        <h5 className="card-title">{workbook.workbook_name}</h5>
                        <h6 className="card-subtitle mb-2 text-muted">
                            {workbook.explanations}
                        </h6>
                        <p className="card-text">
                            Решение:
                            <SyntaxHighlighter language="sql" style={darcula}>
                                {workbook.solution}
                            </SyntaxHighlighter>
                        </p>
                    </div>
                </div>
            ))}
        </VStack>
    );
};

export default WorkbookList;
