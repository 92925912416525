import React from 'react';
import { NavLink } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import logo from './Logo.jpg';

const Header = () => {
    return (
        <Navbar collapseOnSelect expand="lg" style={{ backgroundColor: 'black', color: 'white' }} variant="dark">
            <Navbar.Brand as={NavLink} to="/" style={{ display: 'flex', alignItems: 'center' }}>
                <img src={logo} alt="Brand Logo" style={{ width: '50px', marginRight: '10px' }} />
                <span>SQL-Samurai</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={NavLink} to="/" exact activeClassName="active-link" style={{ marginTop: '3px', marginLeft: '20px' }}>
                        Базы данных
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/about" activeClassName="active-link" style={{ marginTop: '3px', marginLeft: '20px' }}>
                        О проекте
                    </Nav.Link>
                    <Nav.Link as={NavLink} to="/workbook" activeClassName="active-link" style={{ marginTop: '3px', marginLeft: '20px' }}>
                    Сборник задач
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
