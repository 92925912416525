import React from 'react';
import { SyntaxHighlighter, prismDarcula } from 'react-syntax-highlighter';
import CodeMirror from '@uiw/react-codemirror';
import { darcula } from '@uiw/codemirror-theme-darcula';
import { sql } from "@codemirror/lang-sql";

const CodeEditor = ({ query, onQueryChange }) => {
    return (
        <div style={{ maxHeight: '50vh', overflowY: 'auto', width: '100%' }}>
            <CodeMirror
                value={query}
                onChange={onQueryChange}
                theme={darcula}
                extensions={[sql()]}
                basicSetup={{ autocompletion: true }}
                width={'100%'}
                height={'30vh'}
            />
        </div>
    );
};

export default CodeEditor;
