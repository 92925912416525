import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const About = () => {
    return (
        <div className="container mt-5">
            <div className="card mb-4">
                <div className="card-body">
                    <h5 className="card-title">SQL-Samurai</h5>
                    <div className="d-flex flex-row align-items-center">
                        
                        <div>
                            <p className="card-text">
                                Проект по обучению SQL представляет собой уникальную возможность овладеть этим важным инструментом баз данных абсолютно бесплатно. В рамках этого проекта мы предлагаем использовать несколько известных баз данных, таких как Sakila, Chinook и Northwind.
                            </p>
                            <p className="card-text">
                                Sakila — это образец базы данных, который часто используется для обучения. Он моделирует базу данных кинотеатра и содержит таблицы, связанные с фильмами, актерами, клиентами, платежами и т.д. Этот пример позволяет изучать различные аспекты SQL запросов, начиная от простых выборок и заканчивая сложными объединениями таблиц.
                            </p>
                            <p className="card-text">
                                Chinook — ещё один популярный образец базы данных, представляющий собой фиктивный музыкальный магазин. Он содержит информацию о треках, альбомах, артистах, заказах и многом другом. Изучение Chinook позволяет освоить работу с различными типами связей между таблицами и выполнение агрегирующих запросов.
                            </p>
                            <p className="card-text">
                                Northwind — это классическая база данных, разработанная Microsoft для обучения и демонстрации возможностей и функций их продуктов. Она моделирует базу данных фиктивной компании, занимающейся продажей продуктов. Northwind содержит информацию о заказах, клиентах, поставщиках и т.д., что делает её отличным примером для изучения продвинутых SQL запросов и аналитики данных.
                            </p>
                            <p className="card-text">
                                Благодаря использованию этих баз данных, учащиеся могут получить практические навыки в области SQL и глубже понять основы работы с базами данных. Наш проект направлен на то, чтобы сделать обучение SQL доступным для всех и помочь каждому желающему расширить свои знания в этой области.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-4">
                <div className="card-body">
                    <h5 className="card-title">Поддержи проект</h5>
                    <p className="card-text">
                        Над проектом SQL-Samurai кипит работа, и, честно говоря, нам не помешала бы пара чашек кофе ☕️. Если хочешь поддержать наш творческий процесс и сделать наш день чуть лучше, можешь подбросить "на кофе"?
                    </p>
                    <a href="https://www.tinkoff.ru/rm/nigmatullin.danil13/GEN0K90103">
                        Поддержать проект
                    </a>
                    <p className="card-text mt-3">
                        Спасибо тебе, ты лучший(ая)!
                    </p>
                    <p className="card-text">
                        С Уважением, Данил.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default About;
