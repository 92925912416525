import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const PaginationComponent = ({ totalPages, currentPage, onPageChange, onPrevPage, onNextPage }) => {
    const getPageNumbers = () => {
        const totalPageDisplay = 5;
        const pageNumbers = [];

        if (totalPages <= totalPageDisplay) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(
                    <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
                        {i}
                    </Pagination.Item>
                );
            }
        } else {
            let startPage = Math.max(1, currentPage - Math.floor(totalPageDisplay / 2));
            let endPage = Math.min(totalPages, startPage + totalPageDisplay - 1);

            if (endPage - startPage + 1 < totalPageDisplay) {
                startPage = endPage - totalPageDisplay + 1;
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
                        {i}
                    </Pagination.Item>
                );
            }

            if (startPage > 1) {
                pageNumbers.unshift(
                    <Pagination.Ellipsis key="ellipsis-start" onClick={() => onPageChange(startPage - 1)} />
                );
            }

            if (endPage < totalPages) {
                pageNumbers.push(
                    <Pagination.Ellipsis key="ellipsis-end" onClick={() => onPageChange(endPage + 1)} />
                );
            }
        }

        return pageNumbers;
    };

    return (
        <Pagination>
            <Pagination.Prev onClick={onPrevPage} disabled={currentPage === 1} />
            {getPageNumbers()}
            <Pagination.Next onClick={onNextPage} disabled={currentPage === totalPages} />
        </Pagination>
    );
};

export default PaginationComponent;
