import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../Pages/Home';
import About from '../Pages/About';
import Workbook from '../Pages/Workbook';
import DataBases from '../Pages/DataBases';

const AppRoutes = () => {
    return (
        <div className="flex-grow-1">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/workbook" element={<Workbook />} />
                <Route path="/data_bases/:dbName" element={<DataBases />} />
            </Routes>
        </div>
    );
};

export default AppRoutes;
