import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const InfoModal = ({ show, onHide, onShowERD, onShowMatrix }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Информация</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button variant="primary" onClick={onShowERD} style={{ marginRight: '10px' }}>ERD</Button>
                <Button variant="primary" onClick={onShowMatrix}>Matrix</Button>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Закрыть</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InfoModal;
