import { useState, useEffect } from 'react';
import axios from 'axios';

const useWorkbooks = (dbName) => {
    const [workbooks, setWorkbooks] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios({
                    method: 'post',
                    url: `http://5.253.62.148:8001/workbook/${encodeURIComponent(dbName.toLowerCase())}`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: JSON.stringify({ query: "Get data" })
                });

                if (response.data.result && response.data.result.length > 0) {
                    const parsedWorkbooks = response.data.result.map(workbook => ({
                        ...workbook,
                        explanations: JSON.parse(workbook.explanations)['Пояснение'],
                        solution: JSON.parse(workbook.solution)['Решение']
                    }));
                    setWorkbooks(parsedWorkbooks);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [dbName]);

    return workbooks;
};

export default useWorkbooks;
