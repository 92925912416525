// DataBases.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Center, ChakraProvider, Divider, VStack, Flex } from "@chakra-ui/react";
import axios from 'axios';

import CodeEditor from '../Components/CodeEditor';
import PaginationComponent from '../Components/PaginationComponent';
import QueryResultTable from '../Components/QueryResultTable';
import WorkbookList from '../Components/WorkbookList';
import useWorkbooks from '../hooks/useWorkbooks';
import { Button } from 'react-bootstrap';

import InfoModal from '../Components/InfoModal';
import ImageModal from '../Components/ImageModal';

const DataBases = () => {
    const { dbName } = useParams();
    const workbooks = useWorkbooks(dbName);

    const [query, setQuery] = useState('');
    const [result, setResult] = useState([]);
    const [columns, setColumns] = useState([]);
    const [error, setError] = useState(null);
    const [queryExecuted, setQueryExecuted] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(3);

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showERDModal, setShowERDModal] = useState(false);
    const [showMatrixModal, setShowMatrixModal] = useState(false);

    const handleQueryChange = (newValue) => {
        setQuery(newValue);
    };

    const handleExecuteQuery = async () => {
        setError(null);
        try {
            const response = await axios.post(`http://5.253.62.148:8001/${dbName.toLowerCase()}/query`, { query });
            if (response.data.result.length > 0) {
                setColumns(Object.keys(response.data.result[0]));
                setResult(response.data.result);
                setQueryExecuted(true);
            } else {
                setColumns([]);
                setResult([]);
            }
        } catch (error) {
            setError(error.response ? error.response.data.error : 'There was an error executing the query: ' + error.message);
            setColumns([]);
            setResult([]);
        }
    };

    const handleClearAll = () => {
        setQuery('');
        setResult([]);
        setColumns([]);
        setError(null);
        setQueryExecuted(false);
    };

    const totalPages = Math.ceil(workbooks.length / itemsPerPage);

    const handlePrevPage = () => {
        setCurrentPage(prev => prev - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(prev => prev + 1);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentWorkbooks = workbooks.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <ChakraProvider>
            <Center h={queryExecuted ? "210vh" : "180vh"} maxH="150vh" overflow="hidden">
                <VStack spacing={4} w={'80vw'} maxH="100%">
                    <VStack boxShadow={'md'} p={4} borderStyle={"solid"} borderWidth={1} rounded={"lg"} w={'100%'} overflow="hidden">
                        <h4>Вопросы и ответы для {dbName}</h4>
                        <Button onClick={() => setShowInfoModal(true)} style={{ marginBottom: '10px' }}>Информация</Button>
                        <Divider />
                        <WorkbookList workbooks={currentWorkbooks} />
                        <Flex justify="center" mt={4}>
                            <PaginationComponent
                                totalPages={totalPages}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                                onPrevPage={handlePrevPage}
                                onNextPage={handleNextPage}
                            />
                        </Flex>
                    </VStack>
                    <VStack boxShadow={'md'} p={4} borderStyle={"solid"} borderWidth={1} rounded={"lg"} w={'100%'} overflow="hidden">
                        <h4>Редактор кода SQL {dbName}</h4>
                        <CodeEditor query={query} onQueryChange={handleQueryChange} />
                        <div className="table-wrapper" style={{ maxHeight: '100vh', overflowY: 'auto', overflowX: 'auto', width: '100%' }}>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                                    <Button onClick={handleExecuteQuery} style={{ marginRight: '10px' }}>Выполнить</Button>
                                    <Button onClick={handleClearAll} variant="danger">Очистить</Button>
                                </div>
                                <h4>Результат запроса:</h4>
                                <QueryResultTable result={result} columns={columns} error={error} />
                            </div>
                        </div>
                    </VStack>
                    <InfoModal
                        show={showInfoModal}
                        onHide={() => setShowInfoModal(false)}
                        onShowERD={() => { setShowInfoModal(false); setShowERDModal(true); }}
                        onShowMatrix={() => { setShowInfoModal(false); setShowMatrixModal(true); }}
                    />
                    <ImageModal
                        show={showERDModal}
                        onHide={() => setShowERDModal(false)}
                        image={`/${dbName}/ERD.svg`}
                        title="ERD"
                    />
                    <ImageModal
                        show={showMatrixModal}
                        onHide={() => setShowMatrixModal(false)}
                        image={`/${dbName}/Matrix.svg`}
                        title="Matrix"
                    />
                </VStack>
            </Center>
        </ChakraProvider>
    );
};

export default DataBases;