// src/pages/Contacts.js
import React from 'react';

const Workbook = () => {
    return (
        <div className="container">
            <h1>Сборник задач</h1>
            <p>Свяжитесь с нами!</p>
        </div>
    );
};

export default Workbook;