import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import database from '../Components/database.png';

const Home = () => {
    const navigate = useNavigate();

    const handleConnect = (title) => {
        navigate(`/data_bases/${title}`);
    };

    return (
        <Container className="mt-5">
            <Row className="d-flex flex-column align-items-center">
                <Col md={8} className="mb-4">
                    <Card className="d-flex flex-row align-items-center">
                        <Card.Img src={database} style={{ width: '150px' }} />
                        <Card.Body>
                            <Card.Title>Sakila</Card.Title>
                            <Card.Text>
                            Sakila - это база данных, содержит набор таблиц, представляющих вымышленный прокат DVD, включая таблицы для клиентов, фильмов, проката и т. д.  <span style={{ color: 'red' }}>(Используется: PostgreSQL)</span>
                            </Card.Text>
                            <Button variant="primary" onClick={() => handleConnect('Sakila')}>Подключится</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={8} className="mb-4">
                    <Card className="d-flex flex-row align-items-center">
                        <Card.Img src={database} style={{ width: '150px' }} />
                        <Card.Body>
                            <Card.Title>Northwind</Card.Title>
                            <Card.Text>
                            Northwind - это база данных, фиктивной компании, которая торгует продуктами, такими как продукты питания, напитки и другие товары. В базе данных Northwind обычно есть таблицы для заказов, продуктов, клиентов, поставщиков и т. д. <span style={{ color: 'red' }}>(Используется: PostgreSQL)</span>
                            </Card.Text>
                            <Button variant="primary" onClick={() => handleConnect('Northwind')}>Подключится</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={8} className="mb-4">
                    <Card className="d-flex flex-row align-items-center">
                        <Card.Img src={database} style={{ width: '150px' }} />
                        <Card.Body>
                            <Card.Title>Chinook</Card.Title>
                            <Card.Text>
                            Chinook - это база данных, музыкального магазина, включая таблицы для артистов, альбомов, треков, заказов и т. д. <span style={{ color: 'red' }}>(Используется: PostgreSQL)</span>
                            </Card.Text>
                            <Button variant="primary" onClick={() => handleConnect('Chinook')}>Подключится</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
